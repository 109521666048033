<template>
  <div v-if="mkiStatus === 'success'" class="d-flex overflow-hidden">
    <panel-resizable
      :default-width="600"
      :min-width="100"
      class="ant-glass-background radius-0 ant-border-right"
      side="left"
    >
      <v-tabs v-model="tab" grow>
        <v-tab>MKI</v-tab>
        <v-tab>CO2</v-tab>
      </v-tabs>
      <div class="pa-3" style="overflow-y: scroll; height: calc(100% - 48px)">
        <div class="ant-glass-background ma-2 px-2 d-flex align-center">
          <v-select v-model="pieChartColumn" :items="pieChartColumnItems" />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                :color="colorToggle ? 'primary' : ''"
                icon
                v-bind="attrs"
                @click="toggleColorsInModel"
                v-on="on"
              >
                <v-icon> mdi-palette</v-icon>
              </v-btn>
            </template>
            <span>Color model objects</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                :color="groupBottomItems ? 'primary' : ''"
                icon
                v-bind="attrs"
                @click="groupBottomItems = !groupBottomItems"
                v-on="on"
              >
                <v-icon> mdi-format-list-group</v-icon>
              </v-btn>
            </template>
            <span>Group bottom items</span>
          </v-tooltip>
        </div>

        <apexchart
          v-if="pieChartColumn"
          :options="pieChartData.options"
          :series="pieChartData.series"
          class="ant-glass-background ma-2 pa-2"
          height="300px"
          type="pie"
          width="700"
          @dataPointSelection="pieClickHandler"
        />

        <div v-if="selectedObjectName" class="ant-glass-background ma-2 pa-2">
          <v-text-field v-model="selectedObjectName" disabled />
          <apexchart
            :options="barChartData.options"
            :series="barChartData.series"
            height="300px"
            type="bar"
            width="700"
          />
        </div>
      </div>
    </panel-resizable>

    <div class="flex-1">
      <portal to="ant-toolbar">
        <div></div>
      </portal>
      <ant-toolbar-container ant-toolbar-prefix="mki" />
      <forge-viewer
        ref="forge-viewer"
        :ant-toolbar-options="{
          viewType: {
            display: true,
            enabled: false,
          },
          performanceMode: {
            display: true,
            enabled: true,
          },
          models: {
            display: true,
            enabled: false,
          },
          antTable: {
            display: true,
            enabled: false,
          },
          ghosting: {
            display: true,
            enabled: false,
          },
          modelTree: {
            display: true,
            enabled: false,
          },
          clearIsolation: {
            display: true,
            enabled: false,
          },
          objectProperties: {
            display: true,
            enabled: false,
          },
          sidebar: {
            display: true,
            enabled: false,
            width: 400,
          },
        }"
        :client="mkiClient"
        :extension-options="[
          {
            name: 'MkiCo2ImpactExtension',
            options: propertiesOfModels,
          },
        ]"
        :extensions="[
          'Autodesk.DocumentBrowser',
          'MkiCo2ImpactExtension',
          'Autodesk.VisualClusters',
        ]"
        :models="mkiModels"
        :style="{ height: 'calc(100% - 46px)' }"
        ant-toolbar-prefix="mki"
        @modelsRendered="viewerRendered"
        @searchCleared="colorToggle = false"
      />
      <div class="mki-forge-actions">
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <v-btn
              :color="modelsDisplayed ? 'primary' : ''"
              fab
              small
              v-bind="attrs"
              @click="toggleModels"
              v-on="on"
            >
              <v-icon> mdi-table-column</v-icon>
            </v-btn>
          </template>
          <span>Toggle models</span>
        </v-tooltip>
        <v-tooltip v-if="clearSearchToggle" right>
          <template #activator="{ on, attrs }">
            <v-btn
              class="mt-2"
              color="primary"
              fab
              small
              v-bind="attrs"
              @click="clearSearch"
              v-on="on"
            >
              <v-icon> mdi-select-remove</v-icon>
            </v-btn>
          </template>
          <span>Clear search</span>
        </v-tooltip>
      </div>
    </div>
  </div>

  <div v-else class="d-flex justify-center align-center fill-height">
    <ant-loading />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Portal } from 'portal-vue';
import { MKI_IMPACT } from '@/modules/modules';
import ForgeViewer from '@/components/Modules/Forge/ForgeViewer';
import AntLoading from '@/components/AntLoading';
import AntToolbarContainer from '@/components/AntToolbarContainer';
import PanelResizable from '@/components/Project/PanelResizable.vue';
import {
  getObjectsWithMappingLevel,
  hexToVector4,
  pSBC,
  round,
} from '@/components/Modules/Daiwa-House-Modular-Europe/utils/DHME+utils';

export default {
  name: 'MKIImpact',
  components: {
    PanelResizable,
    Portal,
    AntToolbarContainer,
    AntLoading,
    ForgeViewer,
  },
  data: () => {
    return {
      tab: undefined,
      pieChartColumn: undefined,
      propertiesOfModels: [],
      barChartData: {
        options: {},
        series: [],
      },
      pieChartData: {
        options: {},
        series: [],
      },
      allData: [],
      colorToggle: false,
      modelsDisplayed: false,
      displayedModels: [],
      pieChartModel: undefined,
      clearSearchToggle: false,
      selectedObjectName: 'Total',
      groupBottomItems: false,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'mkiStatus',
      'mkiClient',
      'mkiModels',
      'mkiResults',
    ]),
    pieChartColumnItems() {
      switch (this.tab) {
        case 0:
          return [
            'MKI_Totaal_Incl_Toeslag',
            'MKI_Bouw',
            'MKI_Gebruik',
            'MKI_Onderhoud',
            'MKI_EOL',
            'MKI_Toeslag',
          ];
        case 1:
          return [
            'CO2_Totaal_Incl_Toeslag',
            'CO2_Toeslag',
            'CO2_Totaal_Excl_Toeslag',
          ];
      }
      return [];
    },
    modelObjects() {
      return this.$refs['forge-viewer'].modelObjects;
    },
    forgeViewer() {
      return this.$refs['forge-viewer'].viewerService.Viewer3D;
    },
    forgeViewerService() {
      return this.$refs['forge-viewer'].viewerService;
    },
  },
  watch: {
    tab(value) {
      this.pieChartColumn = this.pieChartColumnItems[0];
    },
    pieChartColumn() {
      this.setChartData();
      if (this.colorToggle) {
        this.colorValuesInModel();
      }
    },
    selectedObjectName() {
      this.setBarChartData();
    },
    groupBottomItems() {
      this.setChartData();
    },
  },
  mounted() {
    this.$store.dispatch('fetchMkiModuleData', {
      projectId: this.project.id,
      moduleId: this.project.modules.find(
        (module) => module.route === MKI_IMPACT
      ).id,
      sessionId: this.$route.params.sessionId ?? null,
    });
  },
  methods: {
    viewerRendered(value) {
      this.$refs['forge-viewer'].setExternalMapping(value.myData.urn);
      this.displayedModels = this.$refs['forge-viewer'].models.filter(
        (item) => item.enabled
      );
      this.pieChartModel = this.displayedModels[0]?.name;
      this.tab = 0;
      this.setChartData();
    },
    toggleModels() {
      this.$refs['forge-viewer'].modelsToggle =
        !this.$refs['forge-viewer'].modelsToggle;
      this.modelsDisplayed = this.$refs['forge-viewer'].modelsToggle;
    },
    clearSearch() {
      this.clearSearchToggle = false;
      this.$refs['forge-viewer'].clearSearch();
      this.selectedObjectName = 'Total';
    },
    pieClickHandler(event, chartContext, config) {
      if (config.dataPointIndex >= 0) {
        let objectName =
          this.pieChartData.options.labels[config.dataPointIndex];
        this.clearSearchToggle = true;
        let allModels = this.forgeViewer.impl.modelQueue().getModels().concat();

        this.forgeViewer.hideAll();

        this.modelObjects.forEach((model) => {
          let objectsWithMappingLevel = getObjectsWithMappingLevel(
            model.properties,
            ['Identity Data', 'Type Name']
          );

          let ids = objectsWithMappingLevel.filter((object) =>
            objectName.includes(
              ['Identity Data', 'Type Name'].reduce(
                (o, i) => o[i],
                object.properties
              )
            )
          );

          let objectIds = this.$refs[
            'forge-viewer'
          ].mapExternalIdsToObjectIdsMultiModel(
            model.urn,
            ids.map((x) => x.externalId)
          );

          objectIds.forEach((id) => {
            let viewerModel = allModels.find((x) => model.urn === x.myData.urn);
            this.forgeViewer.show(id, viewerModel);
          });
        });

        this.selectedObjectName = objectName;
      }
    },
    colorValuesInModel() {
      this.forgeViewer.clearThemingColors();
      let allModels = this.forgeViewer.impl.modelQueue().getModels().concat();
      let total = this.allData.map((item) => item.y).reduce((x, y) => x + y, 0);

      this.allData.forEach((item, index) => {
        let objectsWithMappingLevel = getObjectsWithMappingLevel(
          this.modelObjects.find((x) => x.urn === item.urn).properties,
          ['Identity Data', 'Type Name']
        );

        let ids = objectsWithMappingLevel.filter((object) =>
          item.x.includes(
            ['Identity Data', 'Type Name'].reduce(
              (o, i) => o[i],
              object.properties
            )
          )
        );

        let objectIds = this.$refs[
          'forge-viewer'
        ].mapExternalIdsToObjectIdsMultiModel(
          item.urn,
          ids.map((x) => x.externalId)
        );

        if (
          item.y !== null ||
          (item.y !== 0 && this.allData[this.allData.length - 1].y !== 0)
        ) {
          if (item.y < 0) {
            item.y = 0;
          }
          let percent = round(item.total / total, 2);

          let colorHex = pSBC(percent, '#00FF00', '#FF0000', true);

          let color = hexToVector4(colorHex);

          objectIds.forEach((id) => {
            let model = allModels.find((x) => item.urn.includes(x.myData.urn));
            this.forgeViewer.setThemingColor(id, color, model, true);
          });
        }
      });
    },
    setChartData() {
      let series = [];
      this.allData = [];
      this.displayedModels.forEach((model) => {
        let uniqueObjectNames = [
          ...new Set(
            this.mkiResults
              .filter((x) => x.Modelnaam === model.name)
              .map((x) => x.Objectnaam)
          ),
        ];
        let totals = [];
        uniqueObjectNames.forEach((name) => {
          let total = this.mkiResults
            .filter((x) => x.Objectnaam === name && x.Modelnaam === model.name)
            .map((y) => y[this.pieChartColumn])
            .reduce((partial_sum, a) => partial_sum + a, 0);
          totals.push({
            y: Math.round((total + Number.EPSILON) * 100) / 100,
            x: name,
          });
        });

        let sortedData = totals.sort((a, b) =>
          a.y > b.y ? 1 : b.y > a.y ? -1 : 0
        );

        this.allData.push(
          ...sortedData.map((x) => {
            x.model = model.name;
            x.urn = model.urn;
            x.total = sortedData
              .slice(0, sortedData.findIndex((y) => y === x) + 1)
              .map((z) => z.y)
              .reduce((a, b) => a + b, 0);
            return x;
          })
        );

        if (sortedData.length > 10 && this.groupBottomItems) {
          let combined = sortedData.splice(0, sortedData.length - 9);

          let combinedTotal = combined
            .map((item) => item.y)
            .reduce((partial_sum, a) => partial_sum + a, 0);

          sortedData.unshift({
            y: Math.round((combinedTotal + Number.EPSILON) * 100) / 100,
            x: 'Overig',
          });
        }

        series.push({
          name: model.name,
          data: [...sortedData],
        });
      });

      // combine
      let uniqueObjectNames = [...new Set([...this.allData].map((x) => x.x))];
      let totals = [];
      uniqueObjectNames.forEach((name) => {
        let total = [...this.allData]
          .filter((x) => x.x === name)
          .map((y) => y.y)
          .reduce((partial_sum, a) => partial_sum + a, 0);
        totals.push({
          y: Math.round((total + Number.EPSILON) * 100) / 100,
          x: name,
        });
      });

      let sortedData = totals.sort((a, b) =>
        a.y > b.y ? 1 : b.y > a.y ? -1 : 0
      );

      if (sortedData.length > 10 && this.groupBottomItems) {
        let combined = sortedData.splice(0, sortedData.length - 9);

        let combinedTotal = combined
          .map((item) => item.y)
          .reduce((partial_sum, a) => partial_sum + a, 0);

        sortedData.unshift({
          y: Math.round((combinedTotal + Number.EPSILON) * 100) / 100,
          x: 'Overig',
        });
      }

      // pie chart
      this.pieChartData.series = sortedData.map((x) => x.y);
      this.pieChartData.options = {
        pie: {
          size: 300,
        },
        legend: {
          position: 'right',
          fontSize: '8px',
        },
        labels: sortedData.map((x) => x.x),
      };

      if (this.selectedObjectName) {
        this.setBarChartData();
      }
    },
    setBarChartData() {
      let series = [];
      this.displayedModels.forEach((model) => {
        let totals = [];
        this.pieChartColumnItems.forEach((columnName) => {
          let total;
          if (this.selectedObjectName !== 'Total') {
            total = this.mkiResults
              .filter(
                (x) =>
                  x.Objectnaam === this.selectedObjectName &&
                  x.Modelnaam === model.name
              )
              .map((y) => y[columnName])
              .reduce((partial_sum, a) => partial_sum + a, 0);
          } else {
            total = this.mkiResults
              .filter((x) => x.Modelnaam === model.name)
              .map((y) => y[columnName])
              .reduce((partial_sum, a) => partial_sum + a, 0);
          }
          totals.push({
            y: Math.round((total + Number.EPSILON) * 100) / 100,
            x: columnName,
          });
        });

        let sortedData = totals.sort((a, b) =>
          a.y > b.y ? 1 : b.y > a.y ? -1 : 0
        );

        series.push({
          name: model.name,
          data: [...sortedData],
        });
      });

      // bar chart
      this.barChartData.series = series;
      this.barChartData.options = {
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
      };
    },
    toggleColorsInModel() {
      this.colorToggle = !this.colorToggle;
      if (this.colorToggle) {
        this.colorValuesInModel();
      } else {
        this.forgeViewer.clearThemingColors();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mki-content {
  min-width: 600px;
}

.mki-viewer {
  flex: 1;
  position: relative;

  .mki-forge-actions {
    position: absolute;
    left: 20px;
    top: 70px;
    z-index: 10;
    display: flex;
    flex-direction: column;
  }
}
</style>
